// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {

  production: true,
  // URL_SERVICE: 'http://localhost:8080/WSRestApiCoopicbf/APIRest/', //---- Pruebas local

  URL_SERVICE: 'https://tsportalcoopicbfbe.ebsproductodigital.com/WSRestApiCoopicbf/APIRest/', // Servidor de pruebas

  // URL_SERVICE: 'https://coopicbfwebbankingbe.ebscreditodigital.com/WSRestApiCoopicbf/APIRest/',

  // Productos del portal web
  CREDITO: '1',
  APORTES: '2',
  AHORRO_A_LA_VISTA: '3',
  RESERVAS: '4',
  AUXILIOS: '5',
  CDAT: '6', //5
  AHORRO_CONTRACTUAL: '7',
  CONSULTA_DE_SERVICIOS: '8',
  CONSULTA_DE_DEVOLUCIONES: '9',

  //url obtener ip
  URL_CONSULTAR_IP: 'https://api.ipify.org/?format=json'
};

// Productos del portal web PSE
// ng build --output-hashing=all --base-href /coopicbfwebbanking/

// COMPILAR PORTAL WEB
// ng build --base-href /PortalCoopedacTest/ --output-hashing=all

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
//ng build --output-hashing=all --base-href /coopicbf/
