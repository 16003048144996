import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MessageModule } from 'primeng/message';
import { Subject } from 'rxjs';
import { ConfirmationService, Message } from 'primeng//api';
import { MessageService } from 'primeng/api';
import { timer } from 'rxjs';
import { Button } from 'primeng/button';
// import { DialogModule } from 'primeng/dialog';
// import { DialogService } from 'primeng/dynamicdialog';


@Injectable({
  providedIn: 'root',
})
export class TimeOutService implements OnDestroy {
  private inactivityCounter: number;
  private inactivityTime: number = 400; //4
  private inactivityTimeout = this.inactivityTime * 300;//3
  private inactivityInterval;
  private alertShown = false;

  private readonly destroy$ = new Subject<void>();

  constructor(private router: Router, private alertController: MessageService, private confirmationService: ConfirmationService) {
    this.startTimer();
  }


  private startTimer() {
    console.dir('Paso 1');
    this.inactivityInterval = setTimeout(() => {
      console.dir('Paso 2');
      alert('Su sesión a expirado por inactivida')
      this.logout();
    }, this.inactivityTimeout * 1000);
  }

  resetCounter() {
    this.inactivityTimeout = this.inactivityTime * 60;
    this.startTimer();
  }


  logout() {
    clearInterval(this.inactivityInterval);
    localStorage.removeItem('token');
    this.router.navigate(['/cliente/login']);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
