import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, DEFAULT_CURRENCY_CODE } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import es from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { SharedModule } from 'primeng/api';
import { TimeOutService } from './portal/asociado/estado-cuenta/time-out.service';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { ToastModule } from 'primeng/toast';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';

registerLocaleData(es);
@NgModule({
  declarations: [
    AppComponent,

  ],

  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SharedModule,
    DynamicDialogModule,
    ToastModule,
    TableModule,
    ButtonModule,
    DialogModule
  ],

  exports: [
    SharedModule
  ],
  providers: [
    TimeOutService,
    {
      provide: LOCALE_ID,
      useValue: 'es-*',
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: '',
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
